import { Kind } from 'graphql';
import type { ClientError } from 'graphql-request';
import type { GraphQLRequestContext } from 'graphql-request/build/esm/types';
import { gql } from 'graphql-tag';

import { stringify } from '../../utils/common-utils';

export function handleGqlError(error: any) {
  if (isGqlError(error)) {
    logGqlError(error);

    throw new Error('GQL error', { cause: error });
  }
}

export function logGqlError(error: ClientError) {
  const { query, variables } = error.request;
  const name = getGqlQueryName(query);
  if (name) {
    console.error(`[GQL error] query name: ${name}`);
  } else {
    console.error(`[GQL error] query:`);
    console.error(query);
  }
  console.error(`[GQL error] variables: ${stringify(variables)}`);
  console.error(`[GQL error] data: ${stringify(error.response.data)}`);
  console.error(`[GQL error] errors: ${stringify(error.response.errors)}`);
  console.error(`[GQL error] extensions: ${stringify(error.response.extensions)}`);
  console.error(`[GQL error] status: ${error.response.status}`);
}

export function isGqlError(error: any): error is ClientError {
  return !!(error as ClientError).request;
}

function getGqlQueryName(query: GraphQLRequestContext['query']) {
  const parsedQuery = gql(query);
  const operationDefinition = parsedQuery.definitions.find(
    definition => definition.kind === 'OperationDefinition',
  );

  if (operationDefinition?.kind === Kind.OPERATION_DEFINITION && operationDefinition.name) {
    return operationDefinition.name.value;
  }

  return null;
}
