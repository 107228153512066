import { GraphQLClient } from 'graphql-request';

import { graphUrl } from '../../config';

export function getGqlClientSSR(token: string | undefined, websiteKey: string) {
  return new GraphQLClient(graphUrl, {
    headers: {
      website: websiteKey,
      ...(token ? { Authorization: `JWT ${token}` } : null),
    },
    fetch,
  });
}
