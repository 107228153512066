import { useEffect } from 'react';

import { usePermissions } from './usePermissions';
import usePrevious from './usePrevious';

/** Beware of memoizing the callback, e.g. with useCallback, otherwise it may be called even when the auth state doesn't change. */
export function useOnAuthChange(callback: () => void) {
  const { authUserId, isAuthDone } = usePermissions();
  const authUserIdPrev = usePrevious(authUserId);
  const isAuthDonePrev = usePrevious(isAuthDone);
  // console.log({ authUserId, authUserIdPrev, isAuthDone, isAuthDonePrev });

  useEffect(() => {
    const isAuthenticated = !!authUserId;
    const wasAuthenticated = !!authUserIdPrev;
    if (isAuthDonePrev && isAuthDone && isAuthenticated !== wasAuthenticated) {
      callback();
    }
  }, [authUserId, authUserIdPrev, callback, isAuthDone, isAuthDonePrev]);
}
