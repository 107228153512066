import { promises as fs } from 'fs';
import path from 'path';

import { frontConfig } from '../config';
import type { Maybe } from '../types/api';
import { handleError } from './common-utils';

export const fetchMessages = async (locale: string, allowedLanguages?: Maybe<string>[]) => {
  let messages: Record<string, string> = {};
  try {
    const localPath = path.join(process.cwd(), `/public/lang`);
    const files = await fs.readdir(localPath);
    const minLocal = locale.split(/[_-]/)[0];
    const localeIsAllowed =
      !allowedLanguages || allowedLanguages.includes(minLocal) || allowedLanguages.includes(locale);
    const fileName =
      (localeIsAllowed &&
        (files.find(elt => elt.startsWith(locale)) ||
          files.find(elt => elt.startsWith(minLocal)))) ||
      'en-US.json';
    const filePath = path.join(localPath, fileName);

    const fileContents = await fs.readFile(filePath, 'utf8');

    messages = JSON.parse(fileContents);
  } catch (error) {
    if (!frontConfig.IS_DEV) {
      console.error(
        'Missing messages on the server. It will fallback to the default messages in the code. Error:',
      );
      handleError(error);
    }
    messages = {};
  }

  return messages;
};
