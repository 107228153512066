import type { NextPageContext, Redirect } from 'next';

import axios from '../../../front/src/services/axios';
import { isBrowser } from '../../../front/src/utils/fragments/isBrowser';
import { debateConfig } from '../../config';

// From Nextjs type Redirect['statusCode'], but it's a union type that makes it hard to refer to the original type we are interested in.
// We use it instead of number, so that returning it in a page getServerSideProps does not collide with the expected return type.
type StatusCode = 301 | 302 | 303 | 307 | 308;

export const getRedirect = async ({ path }: { path?: string }) => {
  if (!path ?? path?.startsWith('/_')) return null;
  try {
    const { data } = await axios.get<{
      destination_page?: {
        // And lots of other fields
        slug: string;
      };
      destination_tag?: {
        // Field TBC - and lots of other fields
        content: string;
      };
      response_code: StatusCode;
    }>(`/api/pub/redirect?path=${encodeURIComponent(path as string)}`);
    return data;
  } catch (err) {
    if (debateConfig.isDev) {
      throw err;
    } else {
      console.error('Error while fetching the redirect info from the API');
      console.error(err);
      return null;
    }
  }
};

export function redirectFromGetInitialProps(context: NextPageContext, target: Redirect) {
  const { res } = context;
  const basePath = process.env.BASE_PATH || '';

  const destination = `${basePath}${target.destination}`;
  const statusCode = (target as any).statusCode || (target as any).permanent ? 301 : 302;

  if (res) {
    // server
    res.writeHead(statusCode, {
      Location: destination,
    });

    res.end();
  } else if (isBrowser) {
    // client
    window.location.href = destination;
  }
  // You should then return {}

  // For getServerSideProps, instead of calling redirectFromGetInitialProps, it should return something like:
  // return {
  //   redirect: {
  //     destination: `/${tag.content}`,
  //     statusCode,
  //     // permanent: statusCode === 301 || statusCode === 308,
  //   },
  // };
}
