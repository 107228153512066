import type { GetServerSidePropsContext, NextPageContext, Redirect } from 'next';
import { useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { useOnAuthChange } from '../../../front/src/hooks/useOnAuthChange';
import { addApolloState } from '../../../front/src/services/apollo/createApolloClient';
import type { DebateInitialProps } from '../../../front/src/types/debate-types';
import type { PageType } from '../../../front/src/types/layout';
import { isBrowser } from '../../../front/src/utils/fragments/isBrowser';
import { MetaPage } from '../components/Meta/MetaPage';
import type { GetServerSidePropsResp } from '../utils/getServerSidePropsCommon';
import { getServerSidePropsCommon } from '../utils/getServerSidePropsCommon';
import { mkTmpApolloClient } from '../utils/mkTmpApolloClient';
import { getRedirect, redirectFromGetInitialProps } from '../utils/redirect';
import { getPublicKeyFromIncomingMessage } from '../utils/ssr';

const messages = defineMessages({
  pageNotFound: { defaultMessage: "La page n'a pas été trouvée.", id: 'DIgnX8' },
  pageNotAvailable: { defaultMessage: "La page n'est plus disponible.", id: 'fXs5kZ' },
  errorWithCode: { defaultMessage: 'Une erreur {code} est survenue.', id: 'CxuKtb' },
  errorUnknown: { defaultMessage: 'Une erreur inattendue est survenue.', id: 'tQ9qmW' },
});

export interface ErrorPageProps {
  statusCode: number;
}

export const ErrorPage: PageType<ErrorPageProps> = (props: ErrorPageProps) => {
  const { statusCode } = props;

  // If the user signs in or out while on the error page, we refresh it, to give a change to the app to re-render a page, e.g. that would be accessible once signed in, but wasn't before and rendered the error page instead. The error page doesn't have any knowledge of what should be rendered, so we do a full refresh to let the original page render, eventually re-redirecting to the error page.
  const reloadWindow = useCallback(() => {
    if (isBrowser) {
      window.location.reload();
    }
  }, []);
  useOnAuthChange(reloadWindow);

  return (
    <div className="show-more" data-ignore-caching>
      <MetaPage index={false} follow={false} canonicalPath={undefined} />
      {statusCode ? (
        statusCode === 404 ? (
          <FormattedMessage {...messages.pageNotFound} />
        ) : statusCode === 410 ? (
          <FormattedMessage {...messages.pageNotAvailable} />
        ) : (
          <FormattedMessage {...messages.errorWithCode} values={{ code: statusCode }} />
        )
      ) : (
        <FormattedMessage {...messages.errorUnknown} />
      )}
    </div>
  );
};

// Ideally, this error page template should use getServerSideProps. But we must use getInitialProps instead, because of a bug / limitation of nextjs:
// https://nextjs.org/docs/pages/building-your-application/routing/custom-error#caveats
// https://github.com/vercel/next.js/issues/41715
// It means, for a specific case: we make a client-side nav (e.g. redirect after login), we get a 404 (e.g. redirecting to an article we don't have access to, because we're not VIP): the error page does NOT get the SSR props when using getServerSideProps (but it should, that's the bug).
// It works with getInitialProps, so we must use it instead to ensure the props are correctly sent to the page for the client-side nav case.
// If the bug is solved later, we should get back to getServerSideProps and clean up the code in getServerSidePropsCommon and getStaticPropsCommon, that wouldn't need to handle the case of undefined req/res... anymore.

// export const getServerSideProps: GetServerSideProps = async context => {
ErrorPage.getInitialProps = async (context: NextPageContext) => {
  const { req, query } = context;
  const dynamicPublicKey = getPublicKeyFromIncomingMessage(req, query?.website as string);
  let { response /* , website */ } = await getServerSidePropsCommon(
    context as unknown as GetServerSidePropsContext,
    ErrorPage,
    {
      dynamicPublicKey,
    },
  );
  const apolloClient = mkTmpApolloClient(context, dynamicPublicKey);
  delete (response as any)?.notFound; // Should not return "notFound" on the error page
  if (response && ('redirect' in response || 'notFound' in response)) {
    // getInitialProps only (remove for getServerSideProps)
    if ('redirect' in response) {
      redirectFromGetInitialProps(context, response.redirect);
      return {};
    }
    // end

    return response;
  }
  const props = (response ? response.props : {}) as DebateInitialProps;

  const path = req?.url;

  const skip = (path ?? '').startsWith('/_');

  if (!skip) {
    try {
      const redirect = await getRedirect({ path });

      if (redirect && redirect.destination_tag) {
        const { destination_tag: tag, response_code: statusCode = 302 } = redirect;

        const target: Redirect = {
          destination: `/${tag.content}`,
          statusCode,
          // permanent: statusCode === 301 || statusCode === 308,
        };

        // getIP:
        redirectFromGetInitialProps(context, target);
        return {};
        // getSSP:
        // return {
        //   redirect: target,
        // };
      }

      if (redirect && redirect.destination_page) {
        const { destination_page: page, response_code: statusCode = 302 } = redirect;

        const target: Redirect = {
          destination: `/t/${page.slug}`,
          statusCode,
          // permanent: statusCode === 301 || statusCode === 308,
        };

        // getIP:
        redirectFromGetInitialProps(context, target);
        return {};
        // getSSP:
        // return {
        //   redirect: target,
        // };
      }
    } catch (err) {
      console.error(err);
    }
  }

  const statusCode = getStatusCode(context);
  let res2: GetServerSidePropsResp = {
    props: { statusCode, ...props },
  };
  res2 = addApolloState(apolloClient, res2);
  return (res2 as any).props;
};
// };

function getStatusCode(context: NextPageContext) {
  const pageStatusCode = (context.res as any)?.pageStatusCode as number | undefined;
  const statusCode = pageStatusCode || 404;
  if (context.res) {
    context.res.statusCode = statusCode;
  }
  return statusCode;
}

ErrorPage.pageName = () => 'error';

export default ErrorPage;
