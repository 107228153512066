import axios from '../../../front/src/services/axios';

export const defaultSEOPaths = ['/api/seo/common/search', '/api/seo/common/organisation'];

export const getJsonLds = async (jsonLds = '') => {
  try {
    const result = await Promise.all(
      [jsonLds, ...defaultSEOPaths].filter(Boolean).map(url =>
        axios
          .get(url)
          .then(res => res.data)
          .then(_jsonLds => {
            return _jsonLds;
          })
          .catch(() => null),
      ),
    );

    return result.filter(Boolean);
  } catch (err) {
    return [];
  }
};
