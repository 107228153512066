import type { GraphQLClient } from 'graphql-request';
import type {
  GetServerSidePropsContext,
  GetStaticPaths,
  GetStaticProps,
  GetStaticPropsContext,
  GetStaticPropsResult,
} from 'next';

import { defaultLocaleGD } from '../../../front/src/constants/debate-config';
import { getLayout } from '../../../front/src/contributor/api/layout';
import { gcAuthInterceptor } from '../../../front/src/services/axios';
import { getGqlClientSSR } from '../../../front/src/services/graphql/gql-client-ssr';
import { handleGqlError } from '../../../front/src/services/graphql/gql-utils';
import { websiteQuery2 } from '../../../front/src/services/graphql/website-query-2';
import { headingsQuery } from '../../../front/src/thread/api/headings';
import type { QueryType, Website } from '../../../front/src/types/api';
import type { DebateSSRProps2, HeadingsGqlRes } from '../../../front/src/types/debate-types';
import type { PageType } from '../../../front/src/types/layout';
import { fetchMessages } from '../../../front/src/utils/locale';
import { debateConfig } from '../../config';
import { getJsonLds } from './seo';

export type GetStaticPropsResp<Props = {}> =
  | GetStaticPropsResult<DebateSSRProps2<Props>>
  | undefined;
export type GetStaticPropsGD<
  T extends { [key: string]: any } = {},
> /* <Props extends Record<string, any> = {}> */ = GetStaticProps<T> /* <
  Partial<DebateInitialProps<Props>>
> */;

type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type ContextWithOptionalReq = Optional<
  GetServerSidePropsContext,
  'req' | 'res' | 'resolvedUrl'
>;

export interface GetStaticPropsCommon<Props> {
  response: Exclude<GetStaticPropsResp<Props>, undefined>;
  // Undefined if the public key is missing, which should make the app crash anyway; always defined otherwise.
  gqlClient: GraphQLClient;
  website: Website;
}

export interface Extra {
  /** Overrides the public key, for special cases when it is not available in the URL, e.g. _error page */
  dynamicPublicKey?: string;
  /** If provided, the layout is enriched with related topics. For pages on a specific topic that could show related topics. */
  topicId?: string;
  token?: string;
  retrying?: boolean;
  isBot?: boolean;
  isHomepage?: boolean;
}

export async function getStaticPropsCommon<Props = {}>(
  context: GetStaticPropsContext,
  Component: PageType<Props>,
  extra: Extra = {},
): Promise<GetStaticPropsCommon<Props>> {
  try {
    const { params = {}, defaultLocale = defaultLocaleGD, locale = defaultLocale } = context;
    let { website: publicKey } = params;
    if (Array.isArray(publicKey)) publicKey = publicKey[0];
    if (!publicKey) publicKey = extra.dynamicPublicKey;

    if (!publicKey || typeof publicKey !== 'string') {
      console.error(
        'Website key is missing. It is supposed to be found either in a HTTP header or in an environment variable, and next.config.js inserts it to the URL params with a rewrite.',
      );
      return {
        response: { notFound: true },
        gqlClient: undefined!,
        website: undefined!,
      };
    }
    const getPublicKey = () => publicKey as string;
    const token = extra.token || '';
    const getToken = async () => token;
    const gqlClient = getGqlClientSSR(token, publicKey);

    const propsFromRequest = {
      locale: locale,
      publicKey,
      token: '',
    };

    gcAuthInterceptor({ getPublicKey, getToken });

    // Async: call remotes
    let [customLayout, headings, websiteData, jsonLds] = await Promise.all([
      // @TODO: force axios public key
      getLayout({ zone: 'layout' }, extra.topicId),
      gqlClient.request<HeadingsGqlRes>(headingsQuery).catch(err => {
        console.error('[Common SSR] failed to fetch headingsQuery');
        throw err;
      }),
      gqlClient.request<QueryType>(websiteQuery2).catch(err => {
        console.error('[Common SSR] failed to fetch websiteQuery2');
        throw err;
      }),
      getJsonLds().catch(err => {
        console.error('[Common SSR] failed to fetch JSON LDS');
        throw err;
      }),
    ]).catch(e => {
      // The API returns 401 for public routes if the token is invalid.
      if (e?.networkError?.statusCode === 401) {
        return [];
      }
      throw e;
    });

    // me is always null, right? It is legacy code from when it was in getInitialProps, where the request headers could be read.
    // If we really need it on server-side, we need to move the auth/profile part to getServerSideProps.
    const { website } = websiteData;

    if (debateConfig.isDev && !website) {
      throw new Error('website data missing from the SSR');
    }
    const graphdebate = website?.graphdebate;
    if (!graphdebate) throw new Error('graphql website returned a falsy graphdebate object.');

    // TODO the language selection may be moved from embedded nextjs config to the middleware, to pick in the list of BO languages
    const { languages = [] } = graphdebate;

    // Translated labels are loaded here, now we know which language is configured in the BO.
    const messages = await fetchMessages(locale, languages);

    const pageName = typeof Component.pageName === 'function' ? Component.pageName() : '';

    customLayout = customLayout
      // Visible for the current page
      .filter(block => !block.pages.length || block.pages.indexOf(pageName) !== -1);
    // Visible to everybody - done on client side, to work with both the public case and authenticated case. See MainLayout.
    // .filter(
    //   block =>
    //     visibleForEverybody(block.visibility, status) ||
    //     block.visibility.some((visibility: string) => visibility === status),
    // );

    let initialProps = {
      isStatic: true, // Temporary flag as long as some page still rely on getInitialProps.
      isB: extra.isBot ?? true, // fallback to true by default for server side (that can't access the cookie)
      messages,
      headings,
      propsFromRequest,
      // token: getToken,
      // TODO delete the website from the initial props. It is already in the Apollo cache, and it is a very big variable. No need to send it twice to the client.
      // It even generates a warning when loading a page that we exceed the 128 kB recommended threshold.
      // me: me!,
      // website: website!,
      jsonLds,
      customLayout,
      website,
    } as DebateSSRProps2<Props>;

    return {
      response: {
        props: initialProps,
        revalidate: debateConfig.ISR_REVALIDATE || 600,
      },
      gqlClient,
      website,
    };
  } catch (error: any) {
    handleGqlError(error);
    throw error;
  }
}

// Convenience method to indicate that the static generation only happens with ISR, but is not done at build time.
export const defaultStaticPathsNoPreRender: GetStaticPaths = async () => {
  // Don't prerender any static pages
  // (faster builds, but slower initial page load)
  return {
    paths: [],
    fallback: 'blocking' /* debateConfig.isDev ? 'blocking' : true */,
    // Ideally, we should use `true` to have a fallback page, but it would require to much refactoring to allow _app.tsx & co to render without any property from the server. Such a state (no page props) is typically considered a rendering error and it throws an error while rendering.
  };
};

export function redirectToUnavailable() {
  return { redirect: { destination: '/unavailable', permanent: false } };
}

// Minimal function to use as getStaticProps on pages that don't need to extend it.
// You need it for the _app.tsx layout to work as expected.
export const defaultStaticPropsForLayout =
  <Props>(Component: PageType<Props>): GetStaticPropsGD =>
  async context => {
    let { response } = await getStaticPropsCommon(context, Component, {});
    if (response && ('redirect' in response || 'notFound' in response)) {
      return response;
    }

    return response;
  };
