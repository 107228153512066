import type { GetServerSideProps, GetServerSidePropsResult } from 'next';
import { userAgent } from 'next/server';

import type { DebateInitialProps } from '../../../front/src/types/debate-types';
import type { PageType } from '../../../front/src/types/layout';
import type { ContextWithOptionalReq, Extra } from './getStaticPropsCommon';
import { getStaticPropsCommon } from './getStaticPropsCommon';
import { getAuthCookieFromReq } from './ssr';

export type GetServerSidePropsResp = GetServerSidePropsResult<DebateInitialProps> | undefined;
export type GetServerSidePropsGD<Props extends Record<string, any> = {}> = GetServerSideProps<
  Partial<DebateInitialProps<Props>>
>;

export async function getServerSidePropsCommon<Props = {}>(
  context: ContextWithOptionalReq,
  Component: PageType<Props>,
  extra: Extra = {}, // dynamicPublicKey for Error page only, otherwise it is empty
) {
  // Ideally, we should use getStaticProps in most cases. But we haven't solved a use case yet: for AlphaCircle, for which the access is limited to VIP members, check and redirect to the login page if applicable (and redirect out of the login page if already signed in).
  // For the cache, it relies on next-boost for now. Later, we could re-try the getStaticProps + revalidate combo (once the use case above is solved). Last time I tried it, the Lighthouse results were not very consistent. next-boost gave more reliable results.
  const token = getAuthCookieFromReq(context);
  const headers = new Headers(
    !context.req
      ? undefined
      : Object.entries(context.req.headers)
          .map(([k, v]) => [k, Array.isArray(v) ? v[0] : v] as [string, string])
          .filter(([_, v]) => !!v),
  );
  const { isBot } = userAgent({ headers });
  return getStaticPropsCommon(context, Component, { token, isBot, ...extra });
}

// // Minimal function to use as getServerSideProps on pages that don't need to extend it.
// // You need it for the _app.tsx layout to work as expected.
// export const defaultServerSidePropsForLayout =
//   <Props = {}>(Component: PageType<Props>): GetServerSidePropsGD =>
//   async context => {
//     // Common code
//     let { response, apolloClient } = await getServerSidePropsCommon(context, Component);
//     if (response && ('redirect' in response || 'notFound' in response)) {
//       return response;
//     }
//     const props = (response ? response.props : {}) as DebateInitialProps;
//     // /Common code
//
//     let res2: GetServerSidePropsResp = {
//       props: { ...props, pageConfig: { previousPage: 'home' } },
//     };
//     // Common code
//     // res2 = addCacheConfig(res2);
//     res2 = addApolloState(apolloClient, res2);
//     return res2;
//     // /Common code
//   };
