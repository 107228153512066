import { gql } from '@apollo/client';

export const headingsQuery = gql`
  query HeadingsQuery {
    website {
      id
      graphdebate {
        headings {
          content
          uid
          background
          color
          children {
            id
            slug
            title
            count {
              allTopics
            }
          }
        }
      }
    }
  }
`;
