import type { GetStaticPropsContext } from 'next';

import { createApolloClient } from '../../../front/src/services/apollo/createApolloClient';

export function mkTmpApolloClient(context: GetStaticPropsContext, publicKeyOverride?: string) {
  const { params = {} } = context;
  let { website: publicKey } = params;
  if (publicKeyOverride) publicKey = publicKeyOverride;
  if (Array.isArray(publicKey)) publicKey = publicKey[0];
  if (!publicKey || typeof publicKey !== 'string') {
    throw new Error('[mkTmpApolloClient] Missing `website` param in URL');
  }
  const getPublicKey = () => publicKeyOverride || (publicKey as string);
  // No refresh token function passed. No need here.
  const apolloClient = createApolloClient({
    getPublicKey,
    logout: undefined,
  });
  return apolloClient;
}
