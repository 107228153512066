import type { AxiosError } from 'axios';
import type { GraphQLClient } from 'graphql-request';

import { apiUrl } from '../../config';
import axios from '../../services/axios';
import type { GraphdebateHeading } from '../../types/api';
import type { HeadingsGqlRes } from '../../types/debate-types';
import type { HeadingsBlockData, LayoutBlock } from '../../types/layout';
import { fetchHeadingsAPOLLO } from './headings';
import { fetchLastTopicsAPOLLO } from './topics';

type LayoutParams = Partial<
  Pick<LayoutBlock, 'zone' | 'locate' | 'type' | 'visibility' | 'pages' | 'device'>
>;

const paramsToStringsSerializer = (params: LayoutParams) =>
  Object.entries(params).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: Array.isArray(value) ? value.join(',') : value,
    };
  }, {} as { [P in keyof LayoutParams]: string });

export const getLayout = async (params: LayoutParams = {}, topicId?: string) => {
  try {
    const url = new URL('/api/pub/thing', apiUrl);
    // url.search = new URLSearchParams(serializedParams).toString();
    const serializedParams = paramsToStringsSerializer(params);
    let { data } = await axios.get<LayoutBlock[]>(url.toString(), { params: serializedParams });

    if (topicId) {
      data = await addRelatedTopics(data, topicId);
    }

    return data;
  } catch (err) {
    console.warn('Warn: failed to fetch the layout. Is it an error? It was silenced in the past.');
    if ((err as AxiosError)?.response) {
      console.warn(
        (err as AxiosError)?.response?.data,
        (err as AxiosError)?.response?.status,
        (err as AxiosError)?.response?.statusText,
      );
    } else {
      console.warn(err);
    }
    return [] as LayoutBlock[];
  }
};

export async function addRelatedTopics(customLayout: LayoutBlock[], topicId: string) {
  const similarIndex = customLayout.findIndex(b => b.type === 'similar-articles');
  const similar = customLayout[similarIndex];
  if (similar && !similar.data) {
    const relat = await fetchRelatedTopics(similar, topicId);
    customLayout = [...customLayout];
    customLayout.splice(similarIndex, 1, relat);
  }
  return customLayout;
}

const fetchRelatedTopics = async (block: LayoutBlock, pageId: string): Promise<LayoutBlock> => {
  try {
    const { data = {} } = await axios.get(`/api/search/similar/page/${pageId}`);
    const { pages = [] } = data;
    return { ...block, data: pages };
  } catch (err) {
    return block;
  }
};

export const insertLayoutData = async (
  gqlClient: GraphQLClient,
  layout: LayoutBlock[],
  headings?: HeadingsGqlRes,
) => {
  let headingsData: HeadingsBlockData['data'] = [];
  const layoutWithData: LayoutBlock[] = [];

  try {
    for (const block of layout) {
      let blockWithData;
      const { type } = block;

      if (['featured', 'sidebar-featured'].includes(type)) {
        if (!headingsData.length) {
          const fetchedData = headings?.website.graphdebate.headings as GraphdebateHeading[];
          headingsData = fetchedData;
        }
        blockWithData = { ...block, data: headingsData };
      } else {
        blockWithData = await insertBlockData(block, gqlClient);
      }

      layoutWithData.push(blockWithData as LayoutBlock);
    }

    return layoutWithData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const insertBlockData = async (block: LayoutBlock, gqlClient: GraphQLClient) => {
  const { type, options } = block;

  try {
    switch (type) {
      case 'featured':
        return { ...block, data: await fetchHeadingsAPOLLO(gqlClient) };
      case 'sidebar-featured':
        return { ...block, data: await fetchHeadingsAPOLLO(gqlClient) };
      case 'last-topics':
        return { ...block, data: await fetchLastTopicsAPOLLO(gqlClient, options) };
      default:
        return block;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
