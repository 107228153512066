import type { IncomingMessage } from 'http';
import nextCookies from 'next-cookies';

import { TOKEN_NAME } from '../../../front/src/constants/graphlogin';
import { debateConfig } from '../../config';
import type { ContextWithOptionalReq } from './getStaticPropsCommon';

export function getAuthCookieFromReq(ctx: ContextWithOptionalReq, tokenName = TOKEN_NAME) {
  const allCookies = nextCookies(ctx);
  return allCookies[tokenName] ?? '';
}

const DEFAULT_KEY = debateConfig.websiteKey ? debateConfig.publicKeyForDev : 'no-key1';

export function getPublicKeyFromIncomingMessage(
  req: IncomingMessage | undefined,
  fallback?: string,
): string {
  // the header key is case insensitive.
  let headerValue = req?.headers?.['public-key'];
  headerValue = Array.isArray(headerValue) ? headerValue[0] : headerValue;
  return headerValue || fallback || DEFAULT_KEY;
}

export function getPublicKeyFromNextHeadersOrEnv(headers: Headers): string {
  // the header key is case insensitive.
  return headers.get('public-key') || DEFAULT_KEY;
}
